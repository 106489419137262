@import "../../../themes/_colors.scss";
table.chillerTable {
    border-collapse: collapse;
    font-size: 1.2rem;
    height: 23vh;
    margin-top: 1vh;
    width: 96%;
    margin-left: 2%;
    text-align: center;
    color:$grey-shade-4;
}
table.chillerTable tr:nth-child(even) {
    background-color: $grey-shade-3;
}
table.chillerTable tr:nth-child(odd) {
    background-color: $grey-shade-2;
}
.MuiTableRow-root {
  height:"4vh"
}
