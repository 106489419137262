.webclient__filtermodal{
  &-container{
      &-main{
          background-color:white;
          border-radius: 2px;
          width: 40rem;
          height: 30rem;
          border:1px solid gray;
      }
      &-sub{
          display:flex;
          flex-direction:column;
          justify-content:center;
          text-align:center;
      }
      &-start{
          font-size:28;
          padding:15
      }
      &-date{
          width:60%;
          height:4vh;
          text-align:center
      }
      &-display{
          background-color:#33B2FF;
          border:0;
          color:white;
          width:68%;
          height:4vh;
          font-size:16;
      }
      &-chart{
          padding:27;
      }
      &-close {
          font-size: 14px;
          display: flex;
          justify-content: flex-end;
      }
  }
}