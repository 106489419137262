@import "../../../themes/_colors.scss";
.webclient__cardData {
  &-container {
      &-root {
        display: flex;
        background: $white;
        // width: 23.5%;
        // height: 12.5vh;
        justify-content: space-between;
        // padding: 5px;
        border-radius: 4px;
      }
  }
  &-image {
    height: 17px;
    width: 25px;
    // margin-top:2vh
  }
  &-savings{
    // font-size: xx-large;
    color: $darkblue;
    font-weight: 500;
  }
  &-title{
    font-weight: 500;
    color: $grey;
    // font-size:16px;
    // line-height: 19px;
    // margin-left: 1vw;
  }
  &-unit{
      // font-size:14px;
      color: $grey;
  }

}
