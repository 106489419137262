.webclient__analyticsTable{
  &-sortIcon {
    margin-left:5px;
    height: 12px;
    cursor:pointer;
  }
  &-time {
    // width:175px;
    // min-width: 115px;
    border-radius: 4px 0px 0px 0px;
  }
  &-label {
    width:175px;
  }
  &-tableError {
    display:flex;
    justify-content:center;
    font-size:14px;
    // margin-top: 15vh;
  }
}

