@import "../../../themes/_colors";

.webclient__siteOverview {
    &-container {
        &-root {
            display: flex;
            padding: 1%;
            flex-direction: column;
            z-index:1
        }
         &-row{
            display:flex;
            width: 300px;
        }
        &-under{
            margin-left:5%
        }
        &-target{
            color:$grey-shade-1
        }
        &-baseline{
             color:$grey-shade-1;
             margin-left:5%
        }
        &-site {
            display: flex;
            justify-content: space-between;
        }
        &-gauge {
            display: flex;
            justify-content: center;
            height: 25vh;
            width: 14.5%;
            background: #fff;
            border-radius: 4px;
        }
        &-plant {
            display: flex;
            justify-content: space-between;
        }
        &-table {
            width: 37.5%;
            background: #fff;
            height: 25vh;
            border-radius: 4px;
        }
        &-plant-chart {
            display: flex;
            justify-content: space-between;
        }
        &-plant-chart1 {
           background: #fff;
            height: 30vh;
            width: 61%;
            margin-top: 2vh;
            border-radius: 4px;
            // padding:10px 0px 3px 5px;
        }
        &-plant-chart2 {
            background: #fff;
            height: 30vh;
            width: 37%;
            margin-top: 2vh;
            border-radius: 4px;
            // padding:13px 0px 0px 5px;
        }
        &-efficiency {
            display: flex;
            justify-content: space-between;
        }
        &-cooling {
            padding:20px 0px 0px 15px;
            background: #fff;
            height: 30vh;
            border-radius: 4px;
        }
        &-pump {
            display: flex;
            justify-content: space-between;
            padding: 2% 0 2% 0;
            font-size: 14px;
            font-weight: 500;
        }
        &-chiller {
             width: 99%;
        }
        &-chillerplant{
            width:100%;
            display:flex;
            justify-content:space-between;
            // height:12%
        }
    }
    &-plant-text {
        font-weight: 500;
        font-size: 14px;
        padding: 1.5vh 0 0.7vh 0;
    }
    &-site-text {
        font-size: x-large;
        font-weight: 500;
        margin-top: 5rem;
    }
}
i.fa.fa-ellipsis-v.icon {
    font-size: 15px;
}
