@import "../../../themes/_colors";
.webclient__login-screen {
  &-container {
    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      padding-top: 10%;
    //  justify-content: center;
      background: #ffffff;
    }
    &-sub {
      border: 2px solid $darkblue;
      padding-top: 1.5rem;
      height: 380px;
      width: 40%;
      max-width: 400px;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      z-index: 10;
      // justify-content: center;
      align-items: center;
      @media only screen and (max-width: 599px) {
        width: 70%;
      }
      @media only screen and (min-width: 600px) and (max-width: 1030px) {
        width: 40%;
      }
    }
    &-input {
      display: flex;
      height: 45px;
      width: 70%;
      font-size: large;
      margin-bottom: 43px;
    //   padding-left: 0.5rem;
    //   background: #c1c1c1;
      border-bottom: 0;
      border-top: 0;
      border-right: 0;
    //   border-left: 1rem solid #258ff9;
      @media only screen and (max-width: 599px) {
        font-size: small;
        width: 85%;
      }
      @media only screen and (min-width: 600px) and (max-width: 1030px) {
        font-size: medium;
      }

      &-icon {
        width:50px;
      }
    }
  }

  &-header {
    color: $darkblue; // #258ff9;
    font-size: large;
    @media only screen and (max-width: 599px) {
      font-size: medium;
    }
  }
  &-button {
    border: none;
    color: #ffffff;
    background: $darkblue; // #258ff9;
    width: 70%;
    height: 80px;
    // border-radius: 5rem;
    font-size: large;
    padding: 8px 0px;
    outline: none;
    cursor: pointer;
    @media only screen and (max-width: 599px) {
        font-size: small;
        padding: 0px 0px;
        height: 40px;
        width: 85%;
      }
  }
  &-button:disabled,
  &-button[disabled] {
      border: none;
      background-color: #93c4f5;
      color: #ffffff;
  }
  &-button:hover {
    background: $blue; // #258ff9;
  }

  &-forgot-password {
    margin-top: 5px;
    color: $darkblue; // #258ff9;
    cursor: pointer;
    font-size: large;
    @media only screen and (max-width: 599px) {
      font-size: small;
    }
  }
  &-error-text{
    color: red
  }

  &-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-bbp-logo {
      position: fixed;
      z-index: 5;
      top: 1em;
      // height: 20%;
      width: 20%;
  
      @media only screen and (max-height: 500px) {
        left: 0;
      }
    }
  }

  &-bbp-logo {
    position: fixed;
    z-index: 5;
    top: 1em;
    // height: 20%;
    width: 20%;

    @media only screen and (max-height: 500px) {
      left: 0;
    }
  }
  
  &-left-image {
    position: fixed;
    z-index: 0;
    width: 45%;
    left: 0;
    bottom: 0;
  }
  
  &-right-image {
    position: fixed;
    z-index: 0;
    width: 35%;
    right: 0;
    bottom: 0;
  }
}

#company-logo {
  position: fixed;
  z-index: 100;
  top: 1em;
  width: 30%;
}

