@import "../../../themes/_colors.scss";
.webclient__buttonCommon {
    &-container {
        &-root {
            margin-top: 0.7vh;
        }
        &-text {
            border-radius: 4px;
            width: 100%;
            resize: none;
            outline: none;
            border: 1px solid $darkblue; // #3865A7;
            padding: 10px;
            height: 75%;
        }
        &-button {
            display: flex;
            justify-content: flex-end;
            padding-top: 5px;
            height: 40%;
        }
        &-notes {
          border-radius: 4px;
          width: 100%;
          resize: none;
          outline: none;
          border: 1px solid $darkblue; //  #3865A7;
          padding: 10px;
          height: 48%;
      }
    }
    &-label {
        // padding-top: 13px;
        padding-left: 15px;
        color:$black;
    }
    &-durationLabel {
      color:$black;
      padding-top: 6px
  }
    &-button {
        cursor: pointer;
        width: 90px;
        color: $white;
        border: none;
        font-family: Roboto, sans-serif;
        border-radius: 4px;
        height: 25px;
    }
    &-input {
      width: 25px;
      margin-left: 1.6%;
      margin-right: 2%;
      height: 20px;
      border: 1px solid $blue;
      border-radius: 4px;
      outline: none;
      text-align: center;
      font-size: 12px;
      color: $grey-shade-4;
      background: none;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
    }
}
