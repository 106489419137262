@import "../../../themes/_colors";
.webclient__systemheader{
    &-container{
        &-root{
            color: #ffffff;
            background-color: black;
            height:5rem;
            width:100%;
            display:flex;
            align-items:center;
            justify-content: space-between;
            position:fixed;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            z-index: 100;
        }
        &-sub{
            display:flex;
            align-items:center;
             @media only screen and (max-width: 650px) {
                 display:flex;
                 flex-direction:column;
                 font-size: 4px;
            }
        }
         &-sub1{
            display:flex;
            align-items:center;
             @media only screen and (max-width: 650px) {
                 display:flex;
                 font-size: 10px;
            }
        }
        // &-logo{
        //     padding-left:1.5rem
        // }
        &-timezone{
            padding-left:1rem
        }
        &-horizon{
            background:white;
            width:1px;
            height:35px;
            margin-right:8px;
            margin-left:8px;
            @media only screen and (max-width: 650px) {
                  height:30px;
                  margin-right:4px;
                  margin-left:4px;
            }
        }
        &-logout{
            background: $darkblue;
            border:none;
            outline:none;
            cursor: pointer;
        }
        &-greyuser {
            color:gray;
            padding-right:1rem;
            margin-left: 6px;
        }
        &-icon{
          padding-left:8px;
          padding-right:8px
        }
        &-marquee {
            width: 400px;
            line-height: 50px;
            background-color: Pink;
            color: Black;
            white-space: nowrap;
            overflow: hidden;
            box-sizing: border-box;
        }
        &-marquee p {
            display: inline-block;
            padding-left: 100%;
            animation: marquee 10s linear infinite;
        }
        @keyframes marquee {
            0%   { transform: translate(0, 0); }
            100% { transform: translate(-100%, 0); }
        }    }
}