@import "../../../themes/_colors.scss";
.webclient__mapComponent {
  &-mapDark{
    filter:  grayscale(1) invert(2) invert(1) brightness(0.6) contrast(1.8);
    margin: 4px;
  }
  &-mapLight{
    filter: invert(5%) grayscale(4) invert(100%) brightness(3) opacity(0.4);
    margin: 4px;
  }
  &-popupLight {
    .leaflet-popup-content-wrapper {
      border-radius: 4px;
    }
  }
  &-popupDark {
    .leaflet-popup-content-wrapper {
      border-radius: 4px;
      background: #201F25;
    }
    .leaflet-popup-tip {
      background: #201F25;
    }
  }
  &-label{
    padding-top: 5%;
  }
  &-popupMarginOpen{
    z-index: 1;
    .leaflet-popup-content{
      margin: 8px;
    }
  }
  &-popupMarginClose{
    .leaflet-popup-content{
      margin: 3px 2px 3px 3px;
    }
  }

}