.webclient__mapTable {
    &-container {
        &-root {
            display: flex;
            flex-direction: row;
            justify-content: center;
            @media only screen and (max-width: 699px) {
                flex-direction: column;
            }
            // margin-top: 5vh;
            margin-bottom: 5vh;
            // z-index: 0;
        }
        &-right {
            width: 65%;
            height: 80vh;
            // background: #ffffff;
            @media only screen and (max-width: 699px) {
                width: 93vw;
                margin: 5% 0 5% 0;
            }
        }
        &-sub {
            display: flex;
            flex-direction: column;
            padding: 7%;
            align-items: center;
        }
        &-fixed {
          width: 100%;
          height: 7rem;
        }
    }
    &-input {
        display: block;
        height: flex;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        font-size: large;
        padding: 1vh;
        @media only screen and (max-width: 599px) {
          font-size: small;
        }
        @media only screen and (min-width: 600px) and (max-width: 1030px) {
          font-size: medium;
        }
    }
    &-inputRequired {
        display: block;
        height: flex;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        font-size: large;
        padding: 1vh;
        border-color: 'red';
        @media only screen and (max-width: 599px) {
          font-size: small;
        }
        @media only screen and (min-width: 600px) and (max-width: 1030px) {
          font-size: medium;
        }
    }
    &-inputLeft {
        display: block;
        height: flex;
        // margin-left: auto;
        margin-right: auto;
        width: 100%;
        font-size: large;
        padding: 1vh;
        @media only screen and (max-width: 599px) {
            font-size: small;
        }
        @media only screen and (min-width: 600px) and (max-width: 1030px) {
            font-size: medium;
        }
    }
    &-button {
        width: 38%;
        margin-top: 1vh;
        margin-bottom: 1vh;
        cursor: pointer;
        background: deepskyblue;
        border: none;
        height: 4vh;
        @media only screen and (max-width: 699px) {
            width: 60%;
        }
    }
    &-text {
        font-size: 1.3rem;
        padding-bottom: 0.7vh;
    }
    &-error-text{
        color: red
    }
}
