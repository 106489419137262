@import "../../../themes/_colors";
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // width: 37px;
  // position: fixed;
  padding-top: 80px;
  left: 0;
  background-color: $white;
  // transition: 0.5s;
  overflow-x: hidden;
  white-space: nowrap;
  height: 100vh;

}
.open {
  width: auto;
}
.close {
  width: 37px;
}

.arrow {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
.sidebar-link {
  padding: 8px;
  text-decoration: none;
  font-size: 17px;
  color: $grey-shade-4;
  display: flex;
}
.icon-text {
  padding-left: 13px;
  font-size: 12px;
  padding-top: 4px;
  text-transform: none;
  width: 130px;
  // color: $grey-shade-4;
}
.material-icons,
.icon-text {
  vertical-align: middle;
}
.material-icons {
  padding-bottom: 3px;
  margin-right: 30px;
}
.arrow-icon{
  background: $grey-shade-4;
  height: 43px;
  padding-top: 17px;
  padding-left: 6px;
  color: $white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.main {
    transition: margin-left 0.5s;
    height: 100vh;
    overflow: auto;
    flex: 1;
    padding-top: 70px;
    box-sizing: border-box;
}
.pad-top-70{
  padding-top: 70px !important;
}
.logo {
  height: auto;
  width: 100%;
  // padding: 0px 20px;
}
.openLogo{
  padding: 0px 23px;
}
.closeLogo{
  padding: 0px 5px;
}
.main-wrapper {
  display: flex;
}

@media screen and (max-height: 450px) {
  .sidebar {
      padding-top: 15px;
  }
  .sidebar a {
      font-size: 18px;
  }
}
span.MuiSwitch-root {
    margin-bottom: 1rem;
}
.moon{
  margin-left: 27%;
  padding-left:2%
}

.logo{
  margin-left:-5%
}
.rowtoggle{
  display:flex;
}
.sun{
    margin-left: 2.5vw;
}
.copyRight{
  padding-right:9px;
  font-size:13px;
  color: $grey-shade-4;
  text-align: initial;
}
.openicon{
  padding-left:8px;
}
i.fa.fa-angle-left.arrow-icon{
    padding:6px;
    padding-top:10px
}
i.fa.fa-angle-right.arrow-icon{
    padding:7px;
    padding-top:10px;
}