.webclient__modal-close-button {
  &-container {
      &-button {
          background-color: white;
          border-width: inherit;
          color:black;
      }
      &-close{
          padding:15
      }
  }
}