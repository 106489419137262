@import "../../../themes/_colors";
.webclient__header {
    &-container {
        &-root {
            color: #000000;
            // background-color: $header-backgournd;
            display: flex;
            width: 100%;
            height: 3rem;
            -webkit-box-shadow: $header-box-shadow;
            -moz-box-shadow: $header-box-shadow;
            box-shadow: $header-box-shadow;
            box-sizing: border-box;
            padding: 0 2rem;
            align-items: center;
            justify-content: flex-end;
        }
        &-sub {
            display: flex;
        }

        &-content {
          outline: none;
        }
      &-overlay {
          display:flex;
          justify-content:center;
          align-items:center;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          backdrop-filter:blur(19px);
      }
    }

    &-username {
        font-size: 1.2rem;
        padding: 0.2rem 3rem 0 0;
        font-weight: 600;
    }
    &-change-password {
        color: $darkblue; // #258ff9;
        cursor: pointer;
        font-size: 1.2rem;
        text-decoration: underline;
        background: transparent;
        border: none;
    }
    &-filter {
      background: #0066ae;
      height: 4vh;
    }
}
