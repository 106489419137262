@import "../../../themes/_colors";
.webclient__changePassword {
    &-container {
        &-root {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100vh;
            justify-content: center;
        }
        &-sub {
            border: 2px solid #006ab4;
            height: 50vh;
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #ffff;
            @media only screen and (max-width: 599px) {
                width: 70%;
            }
            @media only screen and (min-width: 600px) and (max-width: 1030px) {
                width: 40%;
            }
        }
        &-button {
            align-self: center;
        }
    }
    &-header {
        color: $darkblue; // #258ff9;
        @media only screen and (max-width: 599px) {
            font-size: medium;
        }
    }
    &-label {
        margin-left: 10%;
        font-size: 1.5rem;
    }
    &-input {
        height: 35px;
      width: 70%;
      font-size: large;
      margin-bottom: 43px;
    //   padding-left: 0.5rem;
    //   background: #c1c1c1;
      border-bottom: 0;
      border-top: 0;
      border-right: 0;
    //   border-left: 1rem solid #258ff9;
      @media only screen and (max-width: 599px) {
        font-size: small;
      }
      @media only screen and (min-width: 600px) and (max-width: 1030px) {
        font-size: medium;
      }
    }
    &-button {
        border: none;
    color: #ffffff;
    background: $darkblue; // #258ff9;
    width: 70%;
    height: 43px;
    border-radius: 5rem;
    font-size: medium;
    outline: none;
    cursor: pointer;
    }
    &-button:disabled,
    &-button[disabled] {
        border: none;
        background-color: #93c4f5;
        color: #ffffff;
    }
    &-error-text {
        color: red;
        text-align: center;
    }
}
