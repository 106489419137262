@import "../../../themes/_colors.scss";
.webclient__cardSavings {
  &-container {
      &-root {
        display: flex;
        background: $white;
        width: 23.5%;
        height: 12.5vh;
        justify-content: space-between;
        padding: 5px;
        border-radius: 4px;
      }
      &-box1 {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
      }
      &-box2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
  }
  &-image {
    height: 25px;
    width: 30px;
  }
  &-savings{
    font-size: xx-large;
    color: $blue;
    font-weight: 500;
  }
  &-title{
    font-weight: 500;
    color: $grey;
    line-height: 19px;
    margin-left: 1vw;
  }
  &-unit{
    color: $grey-shade-1;
    font-weight: 500;
  }
  &-percent{
    color: $green;
    background: $grey-shade-6;
    padding: 2px 4px;
  }
}
