@import "../../../themes/_colors.scss";
.webclient__pid {
  &-container{
    &-input{
      padding: 4px;
    }
    &-sub{
      display: flex;
    }
    &-mainInput{
      margin-left: 20px;
    }
  }
&-input1 {
  width: 55px;
  // margin-left: 1.6%;
  // margin-right: 2%;
  // height: 22px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  font-size: 12px;
  // padding-top: 0px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}
&-input2 {
  width: 55px;
  // margin-left: 1.6%;
  // margin-right: 2%;
  // height: 22px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  font-size: 12px;
  background: none;
  // padding-top: 0px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}
&-text {
    padding-top: 3px;
    padding-right: 5px;
    font-size: 11px;
    font-weight: 500;
}
&-sliderText {
    padding-top: 4px;
    font-weight: bold;
    font-size: 11px;
    padding-left: 9px;
}
&-sliderLight {
  .noUi-handle {
    background: #1D6F34;
    box-shadow: none;
    outline: none;
    border: 1px solid #1D6F34;
  }
  .noUi-target {
    background: #E4E7EB;
    border-radius: 0;
    border: 1px solid #E4E7EB;
    left: 60px;
    transform: scaleX(-1);
    box-shadow: none;
    height: 94%;
  }
  .noUi-pips{
    color: #707070;
  }
  .noUi-marker-large{
    background: #707070;
  }
  .noUi-marker{
    background: #707070;
  }
}
&-sliderDark {
  .noUi-handle {
    background: #3AAB2D;
    box-shadow: none;
    outline: none;
    border: 1px solid #3AAB2D;
  }
  .noUi-target {
    background: #3A3A3A;;
    border-radius: 0;
    border: 1px solid #3A3A3A;
    left: 60px;
    transform: scaleX(-1);
    box-shadow: none;
    height: 94%;
  }
  .noUi-pips{
    color: #ACACAC;
  }
  .noUi-marker-large{
    background: #ACACAC;
  }
  .noUi-marker{
    background: #ACACAC;
  }
}
}
.noUi-vertical .noUi-handle {
  height: 6px;
  top: -3px;
  width: 21px;
  right: -8px;
}
.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  height: 0px;
}
.noUi-vertical {
  width: 7px;
}
.noUi-marker-vertical.noUi-marker {
  padding-left: 10px;
  height: 1px;
}
.noUi-rtl .noUi-value-vertical{
  transform: translate(0, 50%) scaleX(-1);
  left: 32px
}
.noUi-base, .noUi-connects {
  height: 99.5%
}