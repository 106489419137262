// COLORS
$red: red;
$black: #000000;
$white: #ffffff;
$blue: #3865A7;
$green: #4CAF50;
$blue-shade-1: #b4ecea;
$blue-shade-2: rgb(54, 199, 209);
$grey: #707070;
$grey-shade-1: #A0A0A0;;
$grey-shade-2: #F7F7F7;
$grey-shade-3: #FBFBFB;
$grey-shade-4:  #707070;
$grey-shade-5: #F2F2F2;
$grey-shade-6: #E8F5E9;
$purple-shade-1: #6c218a;
$black-shade-1:#414141;
// HEADER
$darkblue: #002A41;

// BACKGROUNDS
$primary-background: $white;
$header-backgournd: $white;
$secondary-background: $grey-shade-5;
$button-primary-background: $blue-shade-1;
$button-primary-hover: $blue-shade-2;
$button-secondary-background: $grey-shade-2;
$button-secondary-hover: $grey-shade-3;

// FONTS
$logo-color: $purple-shade-1;
$copyright: $grey-shade-4;

// SHADOW COLOR
$shadow-color-1: $grey-shade-1;

// SHADOW
$header-box-shadow: 0 5px 6px -3px $grey-shade-1;

