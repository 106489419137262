@import "../../../themes/_colors.scss";
.webclient__time {
    &-container {
        &-root {
            display: flex;
            justify-content: space-between;
            padding: 2%;
            align-items: flex-end;
        }
        &-sub {
            display: flex;
            margin-bottom: auto;
        }
        &-date {
            width: 26%;
            display: flex;
            justify-content: flex-end;
        }
    }
    &-input {
        width: 25px;
        margin-left: 1.6%;
        margin-right: 2%;
        height: 22px;
        border: 1px solid $blue;
        border-radius: 3px;
        outline: none;
        text-align: center;
        font-size: 12px;
        font-family: Roboto, sans-serif;
        color: $grey-shade-4;
        background: none;
        padding-top: 0px;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    &-button {
        margin-left: 2%;
        border: none;
        border-radius: 4px;
        color: $white;
        height: 20px;
        font-size: 12px;
        outline: none;
        cursor: pointer;
    }
    &-text {
        color: "#7e7e7e";
        margin-top: 1rem;
        margin-right: 2px;
        margin-left: 0.1px;
        font-weight: 500;
        font-size: 14px;
    }
    &-dropdown {
        border-radius: 4px;
        width: 26%;
        height: 0rem;
    }
    &-image {
        margin-left: 10px;
    }
}

