  .mapMarkerStyle {
    background-color: blue;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    left: -0.5rem;
    top: -0.5rem;
    position: relative;
    border-radius: 1.5rem 1.5rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF;
  }

  .mapMarkerStyle::after {
    position: 'absolute';
    content: '';
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    background-color: #fff;
  }

  /* .leaflet-container {
    width: 100%;
    height: 92%;
    z-index: 0;
  } */

  /* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

.imageDark{

  filter: brightness(0.3) sepia(1) hue-rotate(180deg) saturate(6) !important;
    margin: 4px;
}

.imageLight{
  filter: invert(5%) grayscale(2) invert(80%) brightness(1.8) opacity(0.4) !important;
    margin: 4px;
}
.leaflet-popup-pane, .leaflet-control{
  cursor: pointer;
}