@import "../../../themes/_colors.scss";

.webclient__portfolioView {
    &-container {
        &-header {
            font-family: Roboto;
            font-size: x-large;
            font-weight: 500;
            color:$black-shade-1;
        }
     }
     &-datepaper {
      width: 100%;
    }
}