@import "../../../themes/_colors";
.dial {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:center;
  height: 100%;
  width: 100%;
  margin: 0;
}

.gaugeLabel{
  padding-top: 16px;
  font-size: 13.5px;
  font-weight: 500;
  color: $grey-shade-4;
}

.dialGaugeContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvasContainer {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.currentValue{
  font-size:24;
  font-weight: 500;
}

.valueUnit {
  font-weight: 600;
  color: gray;
}

.label {
  color: gray;
  font-weight: 600;
}

.unit{
  font-weight: 500;
  color: #707070;
  padding-bottom: 16px;
}

// @media only screen and (max-width: 599px) {
//   .chart{
//     height:7vh;
//     width:15vw;
//     margin-top:3rem
//   }
// }

