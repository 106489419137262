@import "../../../themes/_colors.scss";

.webclient__portfolioRightView {
    &-container {
        &-lastest {
            font-weight: 500;
            font-size: 18px;
            color: $black-shade-1;
            display: flex;
            justify-content: space-between;
        }
        &-timeZone {
            font-weight: 500;
            font-size: 12px;
            color: $grey-shade-4;
            padding-bottom: 2px;
        }
        &-hotel {
            padding: 2%;
        }
        &-michael {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            padding-bottom: 2px;
        }
        &-tariff {
            // padding-top: 3vh;
            display: flex;
            flex-direction: row;
        }
        &-number {
            padding: 0 10px;
            font-size: 12px;
            color: $grey-shade-4;
        }
        &-label {
            font-size: 12px;
            color: $grey-shade-4;
        }
        &-subheader {
            margin-top: 3%;
        }
        &-save {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $grey-shade-4;
            margin-left: 0.6vh;
        }
        &-unit {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: $grey-shade-4;
            margin-left: 0.6vh;
        }
        &-root {
            padding: 1vh;
        }
    }
    &-ellipsis{
      padding-top: 0.5vh;
      height: fit-content;
    }
    &-imageKpiSystem{
        height: 37px;
        width: 30px;
    }
    &-imageSystemEfficiency{
        height: 38px;
        width: 35px;
        min-width: 35px;
    }
    &-imagecardPortfolioSavings{
        height: 30px;
        width: 30px;
    }
}
